/* eslint-disable @typescript-eslint/quotes */
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpUrlEncodingCodec,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage-angular";
import { Observable, BehaviorSubject, Subject, from, throwError } from "rxjs";
import { catchError, exhaustMap, map, tap } from "rxjs/operators";;

@Injectable()
export class HttpCallService {
  initiated = false;
  baseUrl: string;

  headers: HttpHeaders;
  //ws_sotf:gY95uZpHGbnLV
  constructor(private storage: Storage, private httpClient: HttpClient) {
    // const authPassSotf = 'd3Nfc290ZjpnWTk1dVpwSEdibkxW';
    // const authPassAutry = 'd3NfYXV0cnk6Jm9xNiY2eTViMEhv';
    this.storage.get("baseUrl")
    .then((baseUrl) => {
      // const authPassSotf = 'd3Nfc290ZjpnWTk1dVpwSEdibkxW';
      // const authPassAutry = 'd3NfYXV0cnk6Jm9xNiY2eTViMEhv';
      let baseCOde = 'd3Nfc290ZjpnWTk1dVpwSEdibkxW';
      if (baseUrl.indexOf('autry') >= 0) {
        baseCOde = 'd3NfYXV0cnk6Jm9xNiY2eTViMEhv';
      }
      console.log("Base code", baseCOde);
      this.headers = new HttpHeaders()
        .set("Authorization", "Basic " + baseCOde)
        .set("Access-Control-Allow-Origin", "*")
        .set("Content-Type", "application/x-www-form-urlencoded");
    });
  }

  setProductTidObservable(tid: string, barcode: string): Observable<any> {
    const body = new URLSearchParams();
    body.set("tid", tid.trim());
    body.set("barcode", barcode.trim());
    console.log("header", this.headers);
    return from(Promise.all([this.storage.get("baseUrl")])).pipe(
      exhaustMap((values) => this.httpClient
          .post<any>(
            values[0] + "api_negozio/associa_tid.php",
            body,
            { headers: this.headers }
          )
          .pipe(catchError(this.handleError)))
    );
  }

  private handleError(error: HttpErrorResponse) {
    let messageError = "";
    if (error.status === 0) {
      console.error("Errore client:", error.error);
      messageError = `Client error status: controllare connessione.`;
    } else {
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
      messageError = `Server error: status code ${error.status}. Contenuto: ${error.error}`;
    }

    return throwError(messageError);
  }
}
