import { Injectable, OnInit } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { RfidObject } from "../classes/rfid-object";

const TIME_TO = 1000;

@Injectable()
export class RfidSystemService {
    public objects: BehaviorSubject<RfidObject[]> = new BehaviorSubject<RfidObject[]>([]);
    private presentsObjects: RfidObject[] = [];

    construct() {}

    addRfidObject(tid: string, time: Date) {
        if (this.presentsObjects.findIndex((obj) => obj.tid === tid) < 0) {
            this.presentsObjects.push({ tid, time });
        } else {
            for (const obj of this.presentsObjects) {
                if (obj.tid === tid) {
                    obj.time = new Date();

                    break;
                }
            }
        }
        const now = new Date();
        this.presentsObjects = this.presentsObjects.filter((obj) => now.getTime() - obj.time.getTime() < TIME_TO);
        this.objects.next(this.presentsObjects);
    }

    removeRfidObject(tid: string, time: Date) {
        setTimeout(() => {
            this.presentsObjects = this.presentsObjects.filter((obj) => obj.tid !== tid);
            this.objects.next(this.presentsObjects);
        }, TIME_TO);
    }

    addRfidObjectAll() {
        setTimeout(() => {
            this.presentsObjects = [];
            this.objects.next(this.presentsObjects);
        }, TIME_TO);
    }

    getObjectsObservable(): Observable<RfidObject[]> {
        return this.objects.asObservable();
    }
}
