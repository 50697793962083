/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @angular-eslint/component-selector */
import { Component, Output, EventEmitter, Input, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { Storage } from "@ionic/storage-angular";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "modal-settings",
  templateUrl: "./modal-settings.html",
})
export class ModalSettingsComponent implements OnInit {
  @Input() id: string;
  settingsForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private storage: Storage
  ) {}

  ngOnInit() {
    this.createForm();
  }

  submitForm() {
    console.log("in component", this.settingsForm.value);
    this.activeModal.close(this.settingsForm.value);
  }

  private createForm() {
    this.settingsForm = this.formBuilder.group({
      hostMqtt: ["", Validators.required],
      portMqtt: ["8083", Validators.required],
      userMqtt: ["", Validators.required],
      passwordMqtt: ["", Validators.required],
      channelMqtt: ["", Validators.required],
      topicMqtt: ["", Validators.required],
      baseUrl: ["", Validators.required],
    });
    this.storage.create().then((data) => {
      try {
        Promise.all([
          this.storage.get("hostMqtt"),
          this.storage.get("portMqtt"),
          this.storage.get("userMqtt"),
          this.storage.get("passwordMqtt"),
          this.storage.get("channelMqtt"),
          this.storage.get("topicMqtt"),
          this.storage.get("baseUrl"),
        ]).then((values) => {
          console.log("values", values);
          const hostMqttFC = this.settingsForm.get("hostMqtt") as FormControl;
          hostMqttFC.setValue(values[0]);
          const portMqttFC = this.settingsForm.get("portMqtt") as FormControl;
          portMqttFC.setValue(values[1]);
          const userMqttFC = this.settingsForm.get("userMqtt") as FormControl;
          userMqttFC.setValue(values[2]);
          const passwordMqttFC = this.settingsForm.get(
            "passwordMqtt"
          ) as FormControl;
          passwordMqttFC.setValue(values[3]);
          const channelMqttFC = this.settingsForm.get(
            "channelMqtt"
          ) as FormControl;
          channelMqttFC.setValue(values[4]);
          const topicMqttFC = this.settingsForm.get("topicMqtt") as FormControl;
          topicMqttFC.setValue(values[5]);
          const baseUrl = this.settingsForm.get("baseUrl") as FormControl;
          baseUrl.setValue(values[6]);
        });
      } catch (err) {}
    });
  }
}
