import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
//import { HttpCallService } from './services/http-call.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { RfidSystemService } from './services/rfid-system.service';
import { ModalSettingsComponent } from './modal-settings/modal-settings';
import { HttpCallService } from './services/http-call.service';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  connectOnCreate: false,
  // hostname: '192.168.1.156',
  // port: 8083,
  // username: 'igtek',
  // password: 'igtek'
};

@NgModule({
  declarations: [
    AppComponent,
    ModalSettingsComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md',
    }),
    AppRoutingModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    HttpClientModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    IonicStorageModule.forRoot({
      name: '__ffw_rfid',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    RfidSystemService,
    HttpCallService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
