import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { Observable, Subject, Subscription } from 'rxjs';
import { ModalSettingsComponent } from './modal-settings/modal-settings';
import { topic2Object, diffInTime, MAX_DIFF_TIME_MILSEC } from './utils';
import { RfidSystemService } from './services/rfid-system.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<null> = new Subject<null>();
  public message: string;
  private subscription: Subscription;
  private checkingInterval: any;
  private lastTime: Date;
  private arriveMessageTime: Date;
  private presentUrl = '';
  private previousUrl = '';

  constructor(
    private mqttService: MqttService,
    private storage: Storage,
    private router: Router,
    private modalService: NgbModal,
    private rfidService: RfidSystemService
  ) {
  }

  ngOnInit() {
    console.log('init');
    this.open();
  }

  open() {
    const modalRef = this.modalService.open(ModalSettingsComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'xl',
    }); //, scrollable: true
    console.log('open', modalRef);
    modalRef.componentInstance.id = 'settings-modal';
    modalRef.result.then(
      (result) => {
        console.log('result', result);
        try {
          this.storage.set('hostMqtt', result.hostMqtt);
          this.storage.set('portMqtt', result.portMqtt);
          this.storage.set('userMqtt', result.userMqtt);
          this.storage.set('passwordMqtt', result.passwordMqtt);
          this.storage.set('channelMqtt', result.channelMqtt);
          this.storage.set('topicMqtt', result.topicMqtt);
          this.storage.set('baseUrl', result.baseUrl);
          this.storage.set('magazzinoId', result.magazzinoId);
        } catch (err) {
          console.log('Errore salvataggio dati', err);
        }

        this.mqttService.connect({
          hostname: result.hostMqtt,
          port: parseInt(result.portMqtt, 10),
          username: result.userMqtt,
          password: result.passwordMqtt,
        });
        // channel: 'igtek/#'
        this.subscription = this.mqttService
          .observe(result.channelMqtt)
          .subscribe((message: IMqttMessage) => {
            this.arriveMessageTime = new Date();
            const topicObj = topic2Object(message.topic);
            console.log("Topic", topicObj);
            if (topicObj.device === result.topicMqtt) {
              console.log("Topic >>> Inner", topicObj.device);
              // "device_2710"
              let payloadObj;
              try {
                console.log("Topic >>> message 2 Json", message.payload.toString());
                payloadObj = JSON.parse(
                  message.payload.toString()
                );
                if (!payloadObj.hasOwnProperty('TID')) {
                  throw new Error('Manca TID');
                }
              } catch (error) {
                console.error(error);
                payloadObj = {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  TID: '-----'
                };
              }
              this.message = payloadObj.TID;
              this.rfidService.addRfidObject(payloadObj.TID, new Date());
            }
          });
      },
      (reason) => {
        console.log('dismiss', reason);
      }
    );
  }

  public unsafePublish(topic: string, message: string): void {
    this.mqttService.unsafePublish(topic, message, {
      qos: 1,
      retain: true,
    });
  }

  public ngOnDestroy() {
    clearInterval(this.checkingInterval);
    this.subscription.unsubscribe();
    this.unsubscribe$.next(null);
  }
}
