/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { differenceInMilliseconds } from 'date-fns';
import { MAX_DIFF_TIME_MILSEC } from './constants';

export interface TopicObject {
  path: string;
  device: string;
  action: string;
}

export function topic2Object(topic: string): TopicObject {
  const topicArr = topic.split("/");
  return {
    path: topicArr[0],
    device: topicArr[1],
    action: topicArr[2]
  };
}

export function diffInTime(prev: Date, next: Date): number {
  if(!prev || !next){
    return MAX_DIFF_TIME_MILSEC;
  }

  return differenceInMilliseconds(next, prev);
}
